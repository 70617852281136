// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const en = {
  good_morning: `Buenos días`,
  good_afternoon: `Buenas tardes`,
  good_evening: `Buenas noches`,
  dashboard: `Tablero`,
  send_now: `Enviar ahora`,
  total_operation_amount: `Monto total de la operación`,
  operation_pending: `Operación pendiente`,
  operation_completed: `Operación completada`,
  operation_rejected: `Operación rechazada`,
  operation_amount: `Monto de la operación`,
  created: `Creado`,
  completed: `Completado`,
  pending: `Pendiente`,
  rejected: `Rechazado`,
  number_of_operations: `Número de operaciones`,
  country_wise_operations: `Operaciones por país`,
  beneficiary_wise_operations: `Operaciones por beneficiario`,
  loading: `Cargando`,
  no_data_available: `No hay datos disponibles`,
  daily: `Diario`,
  weekly: `Semanal`,
  monthly: `Mensual`,
  yearly: `Anual`,
  amount: `Cantidad`,
  count: `Recuento`,
  delete_operation_title: `¿Está seguro de que desea eliminar la operación con ID`,
  delete_operation_warning: `Asegúrese de que no se hayan realizado pagos contra esta operación, ya que eliminarla resultará en la pérdida permanente de los pagos asociados.`,
  operation_list_title: `Operación: Lista`,
  operation_list: `Lista de operaciones`,
  operation: `Operación`,
  new_operation: `Nueva operación`,
  delete: `Eliminar`,
  id: `ID`,
  created_date: `Fecha de creación`,
  sender_mto: `MTO del remitente`,
  end_beneficiary: `Beneficiario final`,
  sent_value: `Valor enviado`,
  recv_value: `Valor recibido`,
  status: `Estado`,
  payment_qr: `Código QR de pago`,
  download: `Descargar`,
  dense: `Denso`,
  operation_deleted_successfully: `Operación eliminada con éxito`,
  operation_delete_failed: `Error al eliminar la operación, inténtelo de nuevo`,
  address_copied_successfully: `Dirección copiada con éxito`,
  error_fetching_address: `Error al obtener la dirección`,
  payment_proof_submitted: `Prueba de pago enviada`,
  payment_info: `Información de pago`,
  example_address: `Ingrese la dirección desde donde transferirá los fondos.`,
  payment_progress: `Progreso del pago`,
  payment_in_progress: `Nos complace informar que su orden de pago #{{referenceNumber}} está en proceso. Esperamos completar esta transacción en los próximos 3 a 5 días hábiles.`,
  already_completed_click_here: `¿Pago ya completado? Haga clic aquí para subir la prueba de pago.`,
  sit_back: `¡Siéntese y relájese mientras verificamos su pago!`,
  payment_doc: `Documento de pago`,
  currency: `Moneda`,
  network: `Red`,
  from_address: `Desde la dirección`,
  add_proof_of_payment: `Agregar prueba de pago`,
  claim_details_submitted: `Detalles de la reclamación enviados`,
  payment_blockchain_hash: `Hash de la blockchain del pago`,
  client_info: `Información del cliente`,
  beneficiary_info: `Información del beneficiario`,
  transfer_details: `Detalles de la transferencia`,
  preview_and_submit: `Vista previa y enviar`,
  funding_source: `Fuente de fondos`,
  funding_source_error: `Error al obtener los datos de la fuente de fondos`,
  operation_created_successfully: `¡Operación creada con éxito!`,
  operation_creation_failed: `Error al crear la operación, inténtelo de nuevo`,
  print: `Imprimir`,
  back: `Atrás`,
  confirm_and_submit: `Confirmar y enviar`,
  next: `Siguiente`,
  incomplete_kyb: `KYB/KYC incompleto`,
  incomplete_kyb_message: `No podemos procesar operaciones hasta que complete su verificación KYC. Complete su KYC para acceder a esta función.`,
  go_to_kyc: `Ir a KYC`,
  relationship_error: `Error al obtener los datos de la relación`,
  document_downloaded: `Documento descargado con éxito`,
  operation_declaration: `Esta transacción está siendo realizada por el remitente y no en nombre de otra persona.`,
  type: `Tipo`,
  purpose: `Propósito`,
  documents: `Documentos`,
  allowed_formats: `*Puede subir archivos de imagen, PDF, documento o Excel.`,
  upload: `Subir`,
  upload_document: `Subir documento`,
  drop_files: `Suelta o selecciona archivo`,
  drop_files_desc: `Suelta archivos aquí o haz clic`,
  browse: `explorar`,
  through_machine: `desde tu máquina`,
  add_new_receiver: `Agregar nuevo receptor`,
  transfer_amount: `Cantidad de transferencia`,
  sender_mto_fee: `Tarifa del remitente MTO`,
  beneficiary_mto_fee: `Tarifa del beneficiario MTO`,
  other_fee: `Otros cargos`,
  total_fee: `Tarifa total`,
  total: `Total`,
  payment_ref_helper: `Formato: El campo debe comenzar con INV_, POP_, o REF_, contener un máximo de 2 líneas de 35x caracteres, con solo guión bajo después de las primeras 3 letras, y separado por '/', sin espacios. Ejemplo INV_RFB/BET072.`,
  total_deposit_amount: `Monto total del depósito`,
  amount_to_payout: `Monto a pagar`,
  sender_country: `País del remitente`,
  payment_reference: `Referencia de pago`,
  purpose_of_remittance: `Propósito de la remesa`,
  purpose_description: `Descripción del propósito`,
  relationship: `Relación`,
  conversion_rate: `Tasa de conversión`,
  date_of_operation: `Fecha de la operación`,
  created_by: `Creado por`,
  first_name: `Nombre`,
  last_name: `Apellido`,
  business_name: `Nombre comercial`,
  primary_contact_name: `Nombre del contacto principal`,
  account_holder_name: `Nombre del titular de la cuenta`,
  account_type: `Tipo de cuenta`,
  country: `País`,
  bank_name: `Nombre del banco`,
  branch: `Sucursal`,
  account_number: `Número de cuenta`,
  swift_bic_code: `Código Swift/BIC`,
  transit_number: `Número de tránsito`,
  aba_routing_number: `Número ABA`,
  ifsc_code: `Código IFSC`,
  iban: `IBAN`,
  lei_code: `Código LEI`,
  address: `Dirección`,
  bank: `Banco`,
  operation_details: `Detalles de la operación`,
  operation_details_title: `Operación: Detalles`,
  sender_details: `Detalles del remitente`,
  phone: `Teléfono`,
  city: `Ciudad`,
  province_state: `Provincia/Estado`,
  beneficiary_details: `Detalles del beneficiario`,
  financial_details: `Detalles financieros`,
  status_history: `Historial de estados`,
  payments: `Pagos`,
  payment_order_number: `Número de orden de pago`,
  receiver_mto: `MTO del receptor`,
  date: `Fecha`,
  operation_status_title: `Operación: Estado`,
  operation_status: `Estado de la operación`,
  operation_initiated: `Operación iniciada`,
  payment_received_from_customer: `Pago recibido del cliente`,
  outgoing_operation_order_assigned: `Orden de operación saliente asignada`,
  passed_aml_kyc_checks: `Pasó las verificaciones AML/KYC`,
  operation_in_progress: `Operación en curso`,
  transferred_to_end_beneficiary: `Transferido al beneficiario final`,
  remarks: `Comentarios`,
  updated_by: `Actualizado por`,
  sender_address: `Dirección del remitente`,
  action: `Acción`,
  no_data: `Sin datos`,
  business_receivers_list_title: `Receptores comerciales: Lista`,
  business_receivers_list: `Lista de receptores comerciales`,
  email: `Correo electrónico`,
  xrpl_tag: `Etiqueta XRPL`,
  business_receivers: `Receptores comerciales`,
  new_receiver: `Nuevo receptor`,
  business_receiver_create_title: `Receptor comercial: Crear`,
  business_receiver: `Receptor comercial`,
  create: `Crear`,
  update: `Actualizar`,
  business_info: `Información comercial`,
  financial_info: `Información financiera`,
  receiver: `Receptor`,
  company_information: `Información de la empresa`,
  contact_details: `Detalles de contacto`,
  business_operating_address: `Dirección operativa de la empresa`,
  user_account_settings_title: `Usuario: Configuración de la cuenta`,
  account: `Cuenta`,
  account_settings: `Configuración de la cuenta`,
  user_info: `Información del usuario`,
  change_password: `Cambiar contraseña`,
  key_controller: `¿Es usted el controlador clave del negocio?`,
  dob: `Fecha de nacimiento`,
  primary_contact_no: `Número de contacto primario`,
  secondary_contact_no: `Número de contacto secundario`,
  postal_code: `Código postal`,
  source_of_wealth: `Fuente de riqueza`,
  tax_id: `ID fiscal`,
  doing_business_as: `Haciendo negocios como`,
  legal_entity: `Entidad legal`,
  business_category: `Categoría comercial`,
  business_sub_category: `Subcategoría comercial`,
  registered_business_address: `Dirección comercial registrada`,
  business_communication_address: `Dirección de comunicación comercial`,
  anticipated_business_activity: `Actividad comercial anticipada`,
  average_transaction_amount: `Monto promedio de transacción`,
  frequency: `Frecuencia`,
  volume: `Volumen`,
  jurisdiction: `Jurisdicción`,
  other_information: `Otra información`,
  timezone: `Zona horaria`,
  company_type: `Tipo de empresa`,
  incorporation_date: `Fecha de incorporación`,
  registration_number: `Número de registro`,
  website: `Sitio web`,
  mcc_code: `Código MCC`,
  save_changes: `Guardar cambios`,
  kyc_not_done_title: `Verifique su cuenta de comerciante para comenzar`,
  kyc_not_done_desc1: `Para ayudar al gobierno a combatir el financiamiento del terrorismo y las actividades de lavado de dinero, la ley federal exige que todas las instituciones financieras adquieran, verifiquen y documenten información que identifique a cada entidad legal y persona que abra una cuenta.`,
  kyc_not_done_desc2: `Para su comprensión: Al abrir una cuenta, solicitaremos detalles como su nombre, dirección, fecha de nacimiento y documentación relevante sobre la entidad legal para la cual se abre la cuenta.`,
  kyc_not_done_desc3: `Esto incluye información sobre sus personas de control y propietarios beneficiarios, lo que facilita nuestra capacidad para identificar tanto a usted como a la entidad legal que representa.`,
  kyc_not_done_desc4: `Además, podemos requerir documentación de identificación para usted, la entidad legal y cualquier entidad o persona afiliada asociada con la entidad legal para la cual se establece la cuenta.`,
  kyc_not_done_desc5: `El proceso de verificación de cuentas generalmente requiere entre 1 y 3 días hábiles.`,
  start_kyb: `Comenzar KYB`,
  kyc_inprocess_title: `Verificación de cuenta en proceso`,
  kyc_inprocess_desc: `El proceso de verificación de cuentas generalmente requiere entre 1 y 3 días hábiles.`,
  kyc_rejected_title: `Verificación de cuenta fallida`,
  kyc_rejected_desc: `Su KYB falló. Haga clic en el botón a continuación para revisar y actualizar la información necesaria para comenzar su negocio.`,
  review_and_update: `Revisar y actualizar`,
  personal_info: `Información personal`,
  personal_documents: `Documentos personales`,
  business_documents: `Documentos comerciales`,
  business_ownership: `Propiedad de la empresa`,
  preview: `Vista previa`,
  kyc_form: `Formulario KYC`,
  kyb_kyc_documents: `Documentos KYB/KYC`,
  close: `Cerrar`,
  note: `NOTA:`,
  note_desc: `Por favor suba su pasaporte y prueba de domicilio (por ejemplo, factura de servicios públicos o estado de cuenta bancario); los ciudadanos venezolanos también deben subir su identificación nacional.`,
  shareholder_note_desc: `Requerimos detalles de todos los propietarios beneficiarios finales (UBO) que posean el 10% o más de la empresa.`,
  id_proof: `Prueba de identificación`,
  front_part_doc: `Parte frontal del documento`,
  back_part_doc: `Parte trasera del documento`,
  address_proof: `Prueba de dirección`,
  remove: `Eliminar`,
  other_documents: `Otros documentos`,
  add_document: `Agregar documento`,
  upload_and_continue: `Subir y continuar`,
  are_you_shareholder: `¿Es usted accionista/UBO de la empresa?`,
  are_you_only_shareholder: `¿Es usted el único accionista/UBO de la empresa?`,
  shareholder_ubo: `Accionista/UBO`,
  entity_type: `Tipo de entidad`,
  add: `Agregar`,
  have_subsidiary: `¿Esta empresa tiene alguna matriz o subsidiaria?`,
  update_and_continue: `Actualizar y continuar`,
  business_reg_address: `Dirección de registro comercial`,
  business_comm_mailing_address: `Dirección de comunicación/mailing comercial`,
  jurisdication: `Jurisdicción`,
  company_reg_date: `Fecha de registro de la empresa`,
  phone_number: `Número de teléfono`,
  ownership: `Propiedad`,
  country_of_reg: `País de registro`,
  citizenship: `Ciudadanía`,
  shareholders: `Accionistas`,
  declaration_title: `Declaración de precisión y autenticidad de la información`,
  declaration_desc: `Por la presente confirmo que toda la información y documentos proporcionados son verdaderos, precisos y legalmente válidos según mi mejor conocimiento. Reconozco que cualquier información falsa o engañosa proporcionada en la documentación puede resultar en consecuencias legales, incluida, entre otras, el rechazo del proceso de registro o acciones legales.`,
  name: `Nombre`,
  id_doc_no: `Número de documento de identificación`,
  position_role: `Posición-Rol`,
  reviewed_and_accepted: `He revisado y acepto los`,
  t_c_and_contract: `términos, condiciones y contrato.`,
  chat: `Chat`,
  new_ticket: `Nuevo ticket`,
  select_connvo_to_start: `Seleccione una conversación para comenzar a chatear.`,
  type_a_message: `Escribe un mensaje...`,
  close_ticket: `Cerrar ticket`,
  reopen_ticket: `Reabrir ticket`,
  are_you_sure: `¿Está seguro de que desea`,
  ticket: `ticket`,
  reopen: `Reabrir`,
  all: `Todo`,
  support: `Soporte`,
  kyc: `KYC`,
  open_tickets: `Tickets abiertos`,
  new: `Nuevo`,
  open: `Abierto`,
  closed: `Cerrado`,
  no_tickets_avail: `¡No hay tickets disponibles!`,
  receivers: `Receptores`,
  profile: `Perfil`,
  user_account: `Cuenta de usuario`,
  user_profile: `Perfil del usuario`,
  user_kyb_kyc: `Usuario KYB/KYC`,
  claim_payment: "Reclamar Pago",
  submit_proof_of_payment: "Enviar Comprobante de Pago",
  proof_of_payment: "Comprobante de Pago",
  verification_completed: "Verificación completada",
  doc_type: "Tipo de documento",
  updated_date: "Última actualización el",
  operation_create: `Operación: Crear`,
};

export default en;
